<template>
    <modal ref="modalEditarImagenCedis" v-loading="loading" :titulo="titulo" icon="cedis" no-aceptar adicional="Actualizar" @adicional="editarImagen">
        <div class="d-flex flex-column justify-content-center align-items-center mx-0 my-3">
            <div class="col-auto text-center text-right">
                <p class="text-general f-14 text-center">
                    Imagen del cedis
                </p>
                <slim-cropper ref="cropCedis" :options="slimOptions" class="border cr-pointer" style="height:160px;width:160px;background:#F8F9FF;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <p class="f-14 text-general text-right">Aspecto 1:1</p>
            </div>
        </div>
    </modal>
</template>

<script>
import cedisImagen from "~/services/configurar/admin/cedisImagen";

export default {
    data(){
        return {
            loading: false,
            model:{
                id: null,
                nombre: '',
                banner: ''
            },
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            titulo: 'Editar imagen del cedis',
            editar: false,
            id: 0,
            foto: ''
        }
    },
    methods: {
        toggle(id,foto){
            this.id = id
            this.foto = foto
            this.foto != "/img/no-imagen/cedis.svg" ? this.$refs.cropCedis.instanciaCrop.load(foto) : this.$refs.cropCedis.instanciaCrop.remove()
            this.$refs.modalEditarImagenCedis.toggle()
        },
        limpiar(){
            this.model = {
                id: null,
                nombre: '',
                banner: ''
            }
            this.$refs.cropCedis.instanciaCrop.remove()
        },
        async editarImagen(){
            try {
                const foto = this.$refs.cropCedis.instanciaCrop.dataBase64.output
                const { data } = await cedisImagen.setImagen(this.id,foto)
                this.$refs.modalEditarImagenCedis.toggle()
                this.$emit('update')
                this.notificacion("","Imagen actualizada con éxito","success")
                this.limpiar()                
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
